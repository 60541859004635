body {
    background: #f8f9fa;
}

hr {
    display: block; height: 1px;
    border: 0; border-top: 1px solid #ccc;
    margin: 1em 0; padding: 0;
}

.btnMain {
    background: rgba(88,12,113,0.7)!important;
    border: 2px solid rgba(145,62,173,0.7)!important;
    color: #fff!important;
    font-weight: 400!important;
    font-size: 20px!important;
    letter-spacing: 1px;
    padding: 12px 25px!important;
    border-radius: 5px!important;
}

.btnMain:hover {
    background: #990099!important;
}

.office-hours th {
    text-align: right;
    width: 44%;
    padding: .1rem .75rem !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}
.office-hours td {
    text-align: left;
    padding: .1rem .75rem !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

/** Overrides */

a:not(.nav-link):not(.btn) {
    color: rgb(159, 0, 199);
    text-decoration: underline;
}
a:hover:not(.nav-link):not(.btn) {
    color: rgb(195, 0, 255);
}

input.form-control, textarea.form-control, select.form-control {
    border-radius: 0px;
    color: rgb(61, 61, 61)!important;
}

.btn-primary{
    color: #fff;
    background-color: #800080;
    border: 1px solid #c01ec0;
    box-shadow: 0px 0px 2px #80008080;
}

.btn-primary:hover{
    color:#fff;
    background-color:#990099;
    border: 1px solid #ff00ff;
    box-shadow: 0px 0px 2px #ff00ff;
}

.btn-primary.focus,.btn-primary:focus{
    box-shadow:0 0 0 .2rem rgba(212, 68, 188, 0.5)
}

.btn-primary.disabled,.btn-primary:disabled{
    color:#fff;
    background-color:#800080;
    border-color:#800080
}

.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle{
    color:#fff;
    background-color:#9c159c;
    border-color:#9c159c
}

.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-primary.dropdown-toggle:focus{
    box-shadow:0 0 0 .2rem rgba(212, 68, 188, 0.5)
}

.fixed-top-3 {
    margin-top: 132px;
    background: linear-gradient(to right, #570c71, #8c34aa, #570c71);
    padding-top: 0;
    padding-bottom: 0;
    border-top: 3px solid #f3a712;
}
.fixed-top-3 a {
    color: #fff;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
}

.fixed-top-3 .nav-link {
    font-weight:400;
    height: 100%;
    display: flex;
    align-items: center;
}
.fixed-top-3 .nav-link.active {
    background: #800080;
    color: #fff;
    box-shadow: 0px 0px 5px rgb(1 1 0 / 50%);
    border: 1px solid #c01ec0;
}
.fixed-top-3 .nav-link:hover {
    color: #ffcc00;
}
.fixed-top-1 .nav-link:hover {
    color: #ffcc00;
}

.fixed-top-2 {
    margin-top: 32px;
    padding: 0px;
}

body {
    padding-top: 186px;
}

.fixed-top-1 .nav {
    width: 600px;
    background: linear-gradient(to right, #570c71, #822c9e, #570c71);
    color: #fff;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

}
.fixed-top-1 .nav-link {
    color: #fff;
    font-weight:400;
}
.navTrapezoid .nav-link {
    padding:4px!important;
}
.navbar-toggler {
    height: 55px;
    color: #fff!important;
}

.page-footer-bar {
    background: linear-gradient(to right, rgba(105,35,128, 0.75), rgba(134,58,160,0.75), rgba(105,35,128, 0.75));
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0px 0px 4px rgb(35 16 44 / 60%);
}

.table-borderless tbody+tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
    border: 0;
}

.wa-hours td {
    text-align: left;
    padding: .1rem .75rem !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.wa-hours th {
    text-align: right;
    width: 44%;
    padding: .1rem .75rem !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.waoNumberBullet tr td {
    vertical-align: top;
}

.waoNumberBullet tr td p {
    margin: 0;
}

@media only screen and (max-width: 576px) {
    .fixed-top-3 {
        margin-top: 32px!important;
    }
    .fixed-top-1 .nav {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

    }
    body {
        padding-top: 86px;
    }
}

@media only screen and (min-width: 1200px) {
    .loggger {
        display: none;
    }
}

li.nav-item {
    display: flex;
    align-items: center;
    text-align: center;
}


/* Default (below 576px) */
#FormCard {
    padding: 22px 0px;
    margin-bottom: 75px;
}
.item {
    margin-bottom: 8px;
}

.btn-delete-item {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.table-rotate {
    margin:0px;
}

/* Very small devices (landscape phones, 576px and up) */
@media (max-width: 575.98px) {
    .btn-delete-item {
        padding: 3px 18px;
        float:right;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .table-rotate {
        /* FF3.5+ */
        -moz-writing-mode: vertical-rl;
        /* Opera 10.5 */
        -o-writing-mode: vertical-rl;
        /* Saf3.1+, Chrome */
        -webkit-writing-mode: vertical-rl;
        /* IE8 */
        -ms-writing-mode: vertical-rl;
        /* Standard */
        writing-mode: vertical-rl;
        margin:0px;
        text-align:center;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    #FormCard {
        margin-top:35px;
    }
    .btn-delete-item {
        padding: 0px 10px;
        padding: 6px 12px;
    }
    .item div {
        padding-right: 8px!important;
    }
    #head-logo {
        height: 80px!important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .table-rotate {
        /* FF3.5+ */
        -moz-writing-mode: vertical-rl;
        /* Opera 10.5 */
        -o-writing-mode: vertical-rl;
        /* Saf3.1+, Chrome */
        -webkit-writing-mode: vertical-rl;
        /* IE8 */
        -ms-writing-mode: vertical-rl;
        /* Standard */
        writing-mode: vertical-rl;
        margin:0px;
        text-align:center;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #FormCard {
        margin-top:35px;
    }
    .btn-delete-item {
        padding: 6px 12px;
    }
    .item div {
        padding-right: 8px!important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #FormCard {
        margin-top:35px;
        padding: 32px 32px;
    }
    .btn-delete-item {
        padding: 6px 12px;
    }
    .item div {
        padding-right: 8px!important;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
    #FormCard {
        margin-top:45px;
        padding: 32px 32px;
    }
    .btn-delete-item {
        padding: 6px 12px;
    }
    .item div {
        padding-right: 8px!important;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    #FormCard {
        margin-top:45px;
        padding: 32px 32px;
    }
    .btn-delete-item {
        padding: 6px 12px;
    }
    .item div {
        padding-right: 8px!important;
    }
}

.grecaptcha-badge {
    display: none;
}
